import React, { useCallback, useMemo, useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { useDropzone } from "react-dropzone";
import { ToastContainer, toast } from "react-toastify";
import ContactImage from "../../images/contactImage.png";
import UploadImage from "../../images/uploadImage.png";

// This file is used at : [ apply.js ]

const ApplyFormComponent = (props) => {
  const { firstName } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const [previewFile, setPreviewFile] = useState({});
  const [myFiles, setMyFiles] = useState([]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...acceptedFiles]);
    },
    [myFiles]
  );

  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [hasFileError, setHasFileError] = useState(false);

  const onDropAccepted = ([file]) => {
    let reader = new FileReader();
    reader.onloadend = () => {
      setHasFileError(false);
      setPreviewFile({
        fileName: file?.path,
        path: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const { acceptedFiles, getRootProps, getInputProps, open } = useDropzone({
    accept: {
      "application/pdf": [".pdf"],
    },
    maxFiles: 1,
    noClick: true,
    noKeyboard: true,
    onDropAccepted,
    maxSize: 6145728,
    onDrop,
  });

  const files = useMemo(() => {
    return myFiles?.map((file) => (
      <li key={file.path} className="apply-fom-section-upload-input-text">
        {file.path}
      </li>
    ));
  }, [myFiles]);

  const onSubmitHandler = (values) => {
    setLoading(true);
    const { firstName, lastName, email, message, fileName } = values;
    console.log(fileName);
    if (!previewFile?.path) {
      setHasFileError(true);
      setLoading(false);
      return;
    }

    const postUrl = `https://api-mail.motomtech.com/send-email`;
    axios
      .post(postUrl, {
        firstName,
        lastName,
        email,
        message,
        attachments: [previewFile],
        subject: "Job Application",
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        setIsSuccess(true);
        setPreviewFile({});
        setMyFiles([]);
        toast.success("Message sent successfully", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        reset();
      })
      .catch((err) => {
        setIsSuccess(false);
        setIsError(true);
        setLoading(false);
        console.log(errors);
        toast.error("There was a problem. Please check again later.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      });
  };
  return (
    <div className="apply-form-section">
      <div className="full-container">
        <div className="apply-form-section-wrapper">
          <div className="row align-items-center">
            <div className="col-md-7 order-2 order-md-1">
              <img src={ContactImage} className="apply-form-section-image" />
            </div>
            <div className="col-md-5 order-1 order-md-2">
              <div className="apply-form-section-submit">
                <form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  className="apply-form-section-submit-form"
                >
                  <label
                    className="apply-form-section-submit-form-label"
                    htmlFor="firstname"
                  >
                    {firstName}
                  </label>
                  <div className="apply-form-section-submit-form-input">
                    <input
                      type="text"
                      className={`apply-form-section-submit-form-input-text ${
                        errors.firstName ? "is-invalid" : ""
                      }`}
                      placeholder="First name"
                      id="firstName"
                      name="firstName"
                      {...register("firstName", {
                        required: "Enter your first name!",
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors.firstName?.message}
                    </div>
                  </div>
                  <label
                    className="apply-form-section-submit-form-label"
                    htmlFor="lastName"
                  >
                    Last Name
                  </label>
                  <div className="apply-form-section-submit-form-input">
                    <input
                      type="text"
                      className={`apply-form-section-submit-form-input-text ${
                        errors.lastName ? "is-invalid" : ""
                      }`}
                      placeholder="Last name"
                      id="lastName"
                      name="lastName"
                      {...register("lastName", {
                        required: "Enter your last name!",
                      })}
                    />
                    <div className="invalid-feedback">
                      {errors.lastName?.message}
                    </div>
                  </div>

                  <div>
                    <label
                      className="apply-form-section-submit-form-label"
                      htmlFor="email"
                    >
                      Email
                    </label>
                    <div className="apply-form-section-submit-form-input">
                      <input
                        type="email"
                        className={`apply-form-section-submit-form-input-text ${
                          errors.email ? "is-invalid" : ""
                        }`}
                        placeholder="you@company.com"
                        id="email"
                        name="email"
                        {...register("email", {
                          required: "Enter your email!",
                        })}
                      />
                      <div className="invalid-feedback">
                        {errors.email?.message}
                      </div>
                    </div>
                  </div>
                  <label
                    className="apply-form-section-submit-form-label"
                    htmlFor="message"
                  >
                    What position are you applying for
                  </label>
                  <div className="apply-form-section-submit-form-input">
                    <textarea
                      rows={4}
                      type="text"
                      placeholder="Position"
                      className="apply-form-section-submit-form-input-text"
                      {...register("message")}
                    />
                  </div>
                  <label
                    className="apply-form-section-submit-form-label"
                    htmlFor="message"
                  >
                    What is your current employment status
                  </label>
                  <div className="apply-form-section-submit-form-input">
                    <textarea
                      rows={4}
                      type="text"
                      placeholder="Select Your Status"
                      className="apply-form-section-submit-form-input-text"
                      {...register("message")}
                    />
                  </div>
                  <label
                    className="apply-form-section-submit-form-label"
                    htmlFor="cv"
                  >
                    Upload Resume
                  </label>
                  <div
                    className={`apply-form-section-submit-form-upload ${
                      hasFileError ? "is-invalid" : ""
                    } `}
                    onClick={open}
                  >
                    <section className={` container `}>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <div className="apply-form-section-submit-form-upload-input">
                          <input {...getInputProps()} />
                          <ul>
                            {files?.length > 0 ? (
                              files
                            ) : (
                              <li className="apply-form-section-submit-form-upload-input-text">
                                <p>Upload File</p>
                                <img src={UploadImage} />
                                <p>Drag and drop files here</p>
                                <p>Pdf Files Only</p>
                              </li>
                            )}
                          </ul>
                        </div>
                      </div>
                    </section>
                  </div>
                  <div className="invalid-feedback">
                    Please select your file
                  </div>
                  <button
                    type="submit"
                    className="apply-form-section-submit-form-btn"
                  >
                    {!loading ? (
                      <>{"Apply"}</>
                    ) : (
                      <div className="spinner-border text-light" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    )}
                  </button>
                </form>
              </div>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <ToastContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ApplyFormComponent;
